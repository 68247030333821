import { createAsyncAction } from 'typesafe-actions';

import { CurrentPickingRoute, CurrentPickingSession, CurrentPickingState, FullBasket, FullOrder, Order, OrderWithItems } from '@models/Order';
import { IFailed, IFailedWithShallow } from './types';

export enum RoutingTypes {
  FETCH_ORDERS = '@@routing/FETCH_ORDERS',
  FETCH_ORDERS_SUCCEEDED = '@@routing/FETCH_ORDERS_SUCCEEDED',
  FETCH_ORDERS_FAILED = '@@routing/FETCH_ORDERS_FAILED',

  FETCH_ORDER_ITEMS = '@@routing/FETCH_ORDER_ITEMS',
  FETCH_ORDER_ITEMS_SUCCEEDED = '@@routing/FETCH_ORDER_ITEMS_SUCCEEDED',
  FETCH_ORDER_ITEMS_FAILED = '@@routing/FETCH_ORDER_ITEMS_FAILED',

  ADD_ORDER_TO_CURRENT_BASKET = '@@routing/ADD_ORDER_TO_CURRENT_BASKET',
  ADD_ORDER_TO_CURRENT_BASKET_SUCCEEDED = '@@routing/ADD_ORDER_TO_CURRENT_BASKET_SUCCEEDED',
  ADD_ORDER_TO_CURRENT_BASKET_FAILED = '@@routing/ADD_ORDER_TO_CURRENT_BASKET_FAILED',

  ADD_ORDER_TO_NEW_BASKET = '@@routing/ADD_ORDER_TO_NEW_BASKET',
  ADD_ORDER_TO_NEW_BASKET_SUCCEEDED = '@@routing/ADD_ORDER_TO_NEW_BASKET_SUCCEEDED',
  ADD_ORDER_TO_NEW_BASKET_FAILED = '@@routing/ADD_ORDER_TO_NEW_BASKET_FAILED',

  FETCH_CURRENT_PICKING_SESSION = '@@routing/FETCH_CURRENT_PICKING_SESSION',
  FETCH_CURRENT_PICKING_SESSION_SUCCEEDED = '@@routing/FETCH_CURRENT_PICKING_SESSION_SUCCEEDED',
  FETCH_CURRENT_PICKING_SESSION_FAILED = '@@routing/FETCH_CURRENT_PICKING_SESSION_FAILED',

  FETCH_CURRENT_BASKET = '@@routing/FETCH_CURRENT_BASKET',
  FETCH_CURRENT_BASKET_SUCCEEDED = '@@routing/FETCH_CURRENT_BASKET_SUCCEEDED',
  FETCH_CURRENT_BASKET_FAILED = '@@routing/FETCH_CURRENT_BASKET_FAILED',

  REMOVE_BASKET_ORDERS = '@@routing/REMOVE_BASKET_ORDERS',
  REMOVE_BASKET_ORDERS_SUCCEEDED = '@@routing/REMOVE_BASKET_ORDERS_SUCCEEDED',
  REMOVE_BASKET_ORDERS_FAILED = '@@routing/REMOVE_BASKET_ORDERS_FAILED',

  AUTO_LOCATE = '@@routing/AUTO_LOCATE',
  AUTO_LOCATE_SUCCEEDED = '@@routing/AUTO_LOCATE_SUCCEEDED',
  AUTO_LOCATE_FAILED = '@@routing/AUTO_LOCATE_FAILED',

  QUICK_ORDER = '@@routing/QUICK_ORDER',
  QUICK_ORDER_SUCCEEDED = '@@routing/QUICK_ORDER_SUCCEEDED',
  QUICK_ORDER_FAILED = '@@routing/QUICK_ORDER_FAILED',

  VALIDATE_ITEM_LOCATION = '@@routing/VALIDATE_ITEM_LOCATION',
  VALIDATE_ITEM_LOCATION_SUCCEEDED = '@@routing/VALIDATE_ITEM_LOCATION_SUCCEEDED',
  VALIDATE_ITEM_LOCATION_FAILED = '@@routing/VALIDATE_ITEM_LOCATION_FAILED',

  CONFIRM_VALIDATE_ITEM_LOCATION = '@@routing/CONFIRM_VALIDATE_ITEM_LOCATION',
  CONFIRM_VALIDATE_ITEM_LOCATION_SUCCEEDED = '@@routing/CONFIRM_VALIDATE_ITEM_LOCATION_SUCCEEDED',
  CONFIRM_VALIDATE_ITEM_LOCATION_FAILED = '@@routing/CONFIRM_VALIDATE_ITEM_LOCATION_FAILED',
  
  VALIDATE_LOCATION_COMPLETE = '@@routing/VALIDATE_LOCATION_COMPLETE',
  VALIDATE_LOCATION_COMPLETE_SUCCEEDED = '@@routing/VALIDATE_LOCATION_COMPLETE_SUCCEEDED',
  VALIDATE_LOCATION_COMPLETE_FAILED = '@@routing/VALIDATE_LOCATION_COMPLETE_FAILED',

  CONFIRM_VALIDATE_LOCATION_COMPLETE = '@@routing/CONFIRM_VALIDATE_LOCATION_COMPLETE',
  CONFIRM_VALIDATE_LOCATION_COMPLETE_SUCCEEDED = '@@routing/CONFIRM_VALIDATE_LOCATION_COMPLETE_SUCCEEDED',
  CONFIRM_VALIDATE_LOCATION_COMPLETE_FAILED = '@@routing/CONFIRM_VALIDATE_LOCATION_COMPLETE_FAILED',

  VALIDATE_ITEM_QUANTITY = '@@routing/VALIDATE_ITEM_QUANTITY',
  VALIDATE_ITEM_QUANTITY_SUCCEEDED = '@@routing/VALIDATE_ITEM_QUANTITY_SUCCEEDED',
  VALIDATE_ITEM_QUANTITY_FAILED = '@@routing/VALIDATE_ITEM_QUANTITY_FAILED',

  CONFIRM_VALIDATE_ITEM_QUANTITY = '@@routing/CONFIRM_VALIDATE_ITEM_QUANTITY',
  CONFIRM_VALIDATE_ITEM_QUANTITY_SUCCEEDED = '@@routing/CONFIRM_VALIDATE_ITEM_QUANTITY_SUCCEEDED',
  CONFIRM_VALIDATE_ITEM_QUANTITY_FAILED = '@@routing/CONFIRM_VALIDATE_ITEM_QUANTITY_FAILED',

  COMPLETE_ITEM_PICK = '@@routing/COMPLETE_ITEM_PICK',
  COMPLETE_ITEM_PICK_SUCCEEDED = '@@routing/COMPLETE_ITEM_PICK_SUCCEEDED',
  COMPLETE_ITEM_PICK_FAILED = '@@routing/COMPLETE_ITEM_PICK_FAILED',

  ABANDON_BASKET = '@@routing/ABANDON_BASKET',
  ABANDON_BASKET_SUCCEEDED = '@@routing/ABANDON_BASKET_SUCCEEDED',
  ABANDON_BASKET_FAILED = '@@routing/ABANDON_BASKET_FAILED',

  CHECKOUT_BASKET = '@@routing/CHECKOUT_BASKET',
  CHECKOUT_BASKET_SUCCEEDED = '@@routing/CHECKOUT_BASKET_SUCCEEDED',
  CHECKOUT_BASKET_FAILED = '@@routing/CHECKOUT_BASKET_FAILED',

  START_PICKING_SESSION = '@@routing/START_PICKING_SESSION',
  START_PICKING_SESSION_SUCCEEDED = '@@routing/START_PICKING_SESSION_SUCCEEDED',
  START_PICKING_SESSION_FAILED = '@@routing/START_PICKING_SESSION_FAILED',

  STOP_PICKING_SESSION = '@@routing/STOP_PICKING_SESSION',
  STOP_PICKING_SESSION_SUCCEEDED = '@@routing/STOP_PICKING_SESSION_SUCCEEDED',
  STOP_PICKING_SESSION_FAILED = '@@routing/STOP_PICKING_SESSION_FAILED',

  SUSPEND_ORDER = '@@routing/SUSPEND_ORDER',
  SUSPEND_ORDER_SUCCEEDED = '@@routing/SUSPEND_ORDER_SUCCEEDED',
  SUSPEND_ORDER_FAILED = '@@routing/SUSPEND_ORDER_FAILED',

  VALIDATE_TOTE_COMPLETE = '@@routing/VALIDATE_TOTE_COMPLETE',
  VALIDATE_TOTE_COMPLETE_SUCCEEDED = '@@routing/VALIDATE_TOTE_COMPLETE_SUCCEEDED',
  VALIDATE_TOTE_COMPLETE_FAILED = '@@routing/VALIDATE_TOTE_COMPLETE_FAILED',

  COMPLETE_ORDER = '@@routing/COMPLETE_ORDER',
  COMPLETE_ORDER_SUCCEEDED = '@@routing/COMPLETE_ORDER_SUCCEEDED',
  COMPLETE_ORDER_FAILED = '@@routing/COMPLETE_ORDER_FAILED',

  FETCH_CURRENT_ROUTE = '@@routing/FETCH_CURRENT_ROUTE',
  FETCH_CURRENT_ROUTE_SUCCEEDED = '@@routing/FETCH_CURRENT_ROUTE_SUCCEEDED',
  FETCH_CURRENT_ROUTE_FAILED = '@@routing/FETCH_CURRENT_ROUTE_FAILED',

  PICKING_SESSION_STATE = '@@routing/PICKING_SESSION_STATE',
  PICKING_SESSION_STATE_SUCCEEDED = '@@routing/PICKING_SESSION_STATE_SUCCEEDED',
  PICKING_SESSION_STATE_FAILED = '@@routing/PICKING_SESSION_STATE_FAILED',
}

export interface IFetchOrders {
  shallow?: boolean;
}
export interface IFetchOrdersSucceeded {
  orders: Order[];
  shallow?: boolean;
}
export const fetchOrders = createAsyncAction(
  RoutingTypes.FETCH_ORDERS,
  RoutingTypes.FETCH_ORDERS_SUCCEEDED,
  RoutingTypes.FETCH_ORDERS_FAILED
)<IFetchOrders, IFetchOrdersSucceeded, IFailedWithShallow>();

export interface IFetchOrderItems {
  orderId: string;
}
export interface IFetchOrderItemsSucceeded {
  order: OrderWithItems;
}
export const fetchOrderItems = createAsyncAction(
  RoutingTypes.FETCH_ORDER_ITEMS,
  RoutingTypes.FETCH_ORDER_ITEMS_SUCCEEDED,
  RoutingTypes.FETCH_ORDER_ITEMS_FAILED
)<IFetchOrderItems, IFetchOrderItemsSucceeded, IFailed>();

export interface IAddOrderToBasket {
  orderId: string;
}
export const addOrderToCurrentBasket = createAsyncAction(
  RoutingTypes.ADD_ORDER_TO_CURRENT_BASKET,
  RoutingTypes.ADD_ORDER_TO_CURRENT_BASKET_SUCCEEDED,
  RoutingTypes.ADD_ORDER_TO_CURRENT_BASKET_FAILED
)<IAddOrderToBasket, void, IFailedWithShallow>();

export const addOrderToNewBasket = createAsyncAction(
  RoutingTypes.ADD_ORDER_TO_NEW_BASKET,
  RoutingTypes.ADD_ORDER_TO_NEW_BASKET_SUCCEEDED,
  RoutingTypes.ADD_ORDER_TO_NEW_BASKET_FAILED
)<IAddOrderToBasket, void, IFailedWithShallow>();

export interface IAbandonBasket {
  basketId: string;
}
export const abandonBasket = createAsyncAction(
  RoutingTypes.ABANDON_BASKET,
  RoutingTypes.ABANDON_BASKET_SUCCEEDED,
  RoutingTypes.ABANDON_BASKET_FAILED
)<IAbandonBasket, void, IFailedWithShallow>();

export interface CheckoutBasket {
  basketId: string;
}
export const checkoutBasket = createAsyncAction(
  RoutingTypes.CHECKOUT_BASKET,
  RoutingTypes.CHECKOUT_BASKET_SUCCEEDED,
  RoutingTypes.CHECKOUT_BASKET_FAILED
)<CheckoutBasket, void, IFailedWithShallow>();

export const fetchCurrentBasket = createAsyncAction(
  RoutingTypes.FETCH_CURRENT_BASKET,
  RoutingTypes.FETCH_CURRENT_BASKET_SUCCEEDED,
  RoutingTypes.FETCH_CURRENT_BASKET_FAILED
)<void, FullBasket | undefined, IFailed>();

export const fetchCurrentSession = createAsyncAction(
  RoutingTypes.FETCH_CURRENT_PICKING_SESSION,
  RoutingTypes.FETCH_CURRENT_PICKING_SESSION_SUCCEEDED,
  RoutingTypes.FETCH_CURRENT_PICKING_SESSION_FAILED
)<void, CurrentPickingSession | undefined, IFailed>();

export interface RemoveBasketOrders {
  basketId: string;
  orderIds: string[];
}
export interface RemoveBasketOrdersSucceeded {
  orderIds: string[];
}
export const removeBasketOrders = createAsyncAction(
  RoutingTypes.REMOVE_BASKET_ORDERS,
  RoutingTypes.REMOVE_BASKET_ORDERS_SUCCEEDED,
  RoutingTypes.REMOVE_BASKET_ORDERS_FAILED
)<RemoveBasketOrders, RemoveBasketOrdersSucceeded, IFailed>();

export interface IAutoLocate {
  basketId: string;
}
export const autoLocate = createAsyncAction(
  RoutingTypes.AUTO_LOCATE,
  RoutingTypes.AUTO_LOCATE_SUCCEEDED,
  RoutingTypes.AUTO_LOCATE_FAILED
)<IAutoLocate, void, IFailedWithShallow>();

export const quickOrder = createAsyncAction(
  RoutingTypes.QUICK_ORDER,
  RoutingTypes.QUICK_ORDER_SUCCEEDED,
  RoutingTypes.QUICK_ORDER_FAILED
)<void, FullOrder | undefined, IFailed>();

export interface IValidateItemLocation {
  barcode: string;
  pickingSessionId: string;
}
export const validateItemLocation = createAsyncAction(
  RoutingTypes.VALIDATE_ITEM_LOCATION,
  RoutingTypes.VALIDATE_ITEM_LOCATION_SUCCEEDED,
  RoutingTypes.VALIDATE_ITEM_LOCATION_FAILED
)<IValidateItemLocation, { pickingTaskId: string, locationId: string, barcode: string }, IFailedWithShallow>();

export interface IConfirmValidateItemLocation {
  pickingSessionId: string;
  pickingTaskId: string;
}
export const confirmValidateItemLocation = createAsyncAction(
  RoutingTypes.CONFIRM_VALIDATE_ITEM_LOCATION,
  RoutingTypes.CONFIRM_VALIDATE_ITEM_LOCATION_SUCCEEDED,
  RoutingTypes.CONFIRM_VALIDATE_ITEM_LOCATION_FAILED
)<IConfirmValidateItemLocation, void, IFailedWithShallow>();

export const validateLocationComplete = createAsyncAction(
  RoutingTypes.VALIDATE_LOCATION_COMPLETE,
  RoutingTypes.VALIDATE_LOCATION_COMPLETE_SUCCEEDED,
  RoutingTypes.VALIDATE_LOCATION_COMPLETE_FAILED
)<IValidateItemLocation, { pickingTaskId: string, locationId: string, barcode: string }, IFailedWithShallow>();

export interface IConfirmValidateItemLocation {
  pickingSessionId: string;
  pickingTaskId: string;
}
export const confirmValidateLocationComplete = createAsyncAction(
  RoutingTypes.CONFIRM_VALIDATE_LOCATION_COMPLETE,
  RoutingTypes.CONFIRM_VALIDATE_LOCATION_COMPLETE_SUCCEEDED,
  RoutingTypes.CONFIRM_VALIDATE_LOCATION_COMPLETE_FAILED
)<IConfirmValidateItemLocation, void, IFailedWithShallow>();

export interface IValidateItemQuantity {
  barcode: string;
  pickingSessionId: string;
  locationId: string;
}
export const validateItemQuantity = createAsyncAction(
  RoutingTypes.VALIDATE_ITEM_QUANTITY,
  RoutingTypes.VALIDATE_ITEM_QUANTITY_SUCCEEDED,
  RoutingTypes.VALIDATE_ITEM_QUANTITY_FAILED
)<IValidateItemQuantity, { itemName: string, requiredQuantity: number }, IFailedWithShallow>();

export interface IConfirmValidateItemQuantity {
  pickingSessionId: string;
  pickingTaskId: string;
  quantity: number;
}
export const confirmValidateItemQuantity = createAsyncAction(
  RoutingTypes.CONFIRM_VALIDATE_ITEM_QUANTITY,
  RoutingTypes.CONFIRM_VALIDATE_ITEM_QUANTITY_SUCCEEDED,
  RoutingTypes.CONFIRM_VALIDATE_ITEM_QUANTITY_FAILED
)<IConfirmValidateItemQuantity, void, IFailedWithShallow>();

export interface CompleteItemPick {
  pickingSessionId: string;
  pickingTaskId: string;
}
export const completeItemPick = createAsyncAction(
  RoutingTypes.COMPLETE_ITEM_PICK,
  RoutingTypes.COMPLETE_ITEM_PICK_SUCCEEDED,
  RoutingTypes.COMPLETE_ITEM_PICK_FAILED
)<CompleteItemPick, void, IFailedWithShallow>();

export interface StartPickingSession {
  basketId: string;
}
export const startPickingSession = createAsyncAction(
  RoutingTypes.START_PICKING_SESSION,
  RoutingTypes.START_PICKING_SESSION_SUCCEEDED,
  RoutingTypes.START_PICKING_SESSION_FAILED
)<StartPickingSession, { id: string }, IFailedWithShallow>();

export interface StopPickingSession {
  pickingSessionId: string;
}
export const stopPickingSession = createAsyncAction(
  RoutingTypes.STOP_PICKING_SESSION,
  RoutingTypes.STOP_PICKING_SESSION_SUCCEEDED,
  RoutingTypes.STOP_PICKING_SESSION_FAILED
)<StopPickingSession, void, IFailedWithShallow>();

export interface SuspendOrder {
  pickingSessionId: string;
  pickingTaskId: string;
}
export const suspendOrder = createAsyncAction(
  RoutingTypes.SUSPEND_ORDER,
  RoutingTypes.SUSPEND_ORDER_SUCCEEDED,
  RoutingTypes.SUSPEND_ORDER_FAILED
)<SuspendOrder, void, IFailedWithShallow>();

export interface ValidateToteComplete {
  pickingSessionId: string;
  pickingTaskId: string;
  orderBarcode: string;
}
export const validateToteComplete = createAsyncAction(
  RoutingTypes.VALIDATE_TOTE_COMPLETE,
  RoutingTypes.VALIDATE_TOTE_COMPLETE_SUCCEEDED,
  RoutingTypes.VALIDATE_TOTE_COMPLETE_FAILED
)<ValidateToteComplete, void, IFailedWithShallow>();

export interface CompleteOrder {
  pickingSessionId: string;
  pickingTaskId: string;
}
export const completeOrder = createAsyncAction(
  RoutingTypes.COMPLETE_ORDER,
  RoutingTypes.COMPLETE_ORDER_SUCCEEDED,
  RoutingTypes.COMPLETE_ORDER_FAILED
)<CompleteOrder, void, IFailedWithShallow>();

export interface FetchCurrentSession {
  pickingSessionId: string;
}

export const fetchCurrentRoute = createAsyncAction(
  RoutingTypes.FETCH_CURRENT_ROUTE,
  RoutingTypes.FETCH_CURRENT_ROUTE_SUCCEEDED,
  RoutingTypes.FETCH_CURRENT_ROUTE_FAILED
)<FetchCurrentSession, CurrentPickingRoute, IFailedWithShallow>();

export const pickingSessionState = createAsyncAction(
  RoutingTypes.PICKING_SESSION_STATE,
  RoutingTypes.PICKING_SESSION_STATE_SUCCEEDED,
  RoutingTypes.PICKING_SESSION_STATE_FAILED
)<FetchCurrentSession, CurrentPickingState, IFailedWithShallow>();
