import React, { Component } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AppBar from '@mui/material/AppBar';
import CircularProgress from '@mui/material/CircularProgress';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import {
  CompleteItemPick,
  CompleteOrder,
  FetchCurrentSession,
  IConfirmValidateItemLocation,
  IConfirmValidateItemQuantity,
  StopPickingSession,
  ValidateToteComplete,
} from '@actions/routing';
import LoadingIconButton from '@app/common/LoadingButton/LoadingIconButton';
import { CurrentPickingRoute, CurrentPickingState, CurrentPickingStateTask } from '@models/Order';
import LoadingButton from '@app/common/LoadingButton';

interface IProps {
  confirmLocationLoading: boolean;
  confirmLocationCompleteLoading: boolean;
  confirmQuantityLoading: boolean;
  loading: boolean;
  confirmedByAPI: boolean;
  confirmedByAPI2: boolean;
  confirmedByAPI3: boolean;
  router: any;
  language: string;
  pickingTaskId?: string;
  locationBarcode?: string;
  pickingTaskCompleteId?: string;
  locationCompleteBarcode?: string;
  itemName?: string;
  requiredQuantity?: number;
  confirmValidateItemLocation: (properties: IConfirmValidateItemLocation) => void;
  confirmValidateLocationComplete: (properties: IConfirmValidateItemLocation) => void;
  confirmValidateItemQuantity: (properties: IConfirmValidateItemQuantity) => void;
  stopPickingSessionLoading: boolean;
  stopPickingSessionComplete: boolean;
  stopPickingSession: (properties: StopPickingSession) => void;
  validateToteCompleteLoading: boolean;
  validateToteCompleteComplete: boolean;
  validateToteComplete: (properties: ValidateToteComplete) => void;
  completeItemPickLoading: boolean;
  completeItemPickConfirmed: boolean;
  completeItemPick: (properties: CompleteItemPick) => void;
  currentRoute?: CurrentPickingRoute;
  currentState?: CurrentPickingState;
  currentStateLoading: boolean;
  pickingSessionState: (properties: FetchCurrentSession) => void;
  completeOrderLoading: boolean;
  completeOrderComplete: boolean;
  completeOrder: (properties: CompleteOrder) => void;
}
interface IState {
  interfaceType: 'location' | 'item';
  data: string;
  confirmed: boolean;
  showConfirm: boolean;
  validOrder: boolean;
}

const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  bottom: 40,
  left: 0,
  right: 0,
  margin: '0 auto',
});

const StyledQtFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  right: 0,
  bottom: -25,
});


class ScanPlaceholder extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      interfaceType: props.router.params.type || 'location',
      data: props.router.params.data || '',
      confirmed: false,
      showConfirm: false,
      validOrder: false,
    };

    this.confirm = this.confirm.bind(this);
    this.home = this.home.bind(this);
    this.stopPickingSession = this.stopPickingSession.bind(this);
    this.validateOrder = this.validateOrder.bind(this);
    this.comfirmOrder = this.comfirmOrder.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    const {
      confirmLocationLoading,
      confirmLocationCompleteLoading,
      confirmQuantityLoading,
      confirmedByAPI,
      confirmedByAPI2,
      confirmedByAPI3,
      language,
      locationBarcode,
      locationCompleteBarcode,
      itemName,
      stopPickingSessionLoading,
      stopPickingSessionComplete,
      validateToteCompleteLoading,
      validateToteCompleteComplete,
      completeItemPickLoading,
      completeOrderLoading,
      completeOrderComplete,
      completeItemPickConfirmed,
      pickingSessionState,
      currentRoute,
      currentStateLoading,
      currentState,
      router,
    } = this.props;
    const { interfaceType } = this.state;

    if (
      (prevProps.confirmLocationLoading !== confirmLocationLoading && prevProps.confirmLocationLoading && confirmedByAPI && locationBarcode) ||
      (prevProps.confirmLocationCompleteLoading !== confirmLocationCompleteLoading && prevProps.confirmLocationCompleteLoading && confirmedByAPI2 && locationCompleteBarcode) ||
      (prevProps.confirmQuantityLoading !== confirmQuantityLoading && prevProps.confirmQuantityLoading && confirmedByAPI3 && itemName)
    ) {
      const currentTask = (currentRoute?.tasks || []).find((p) => !p.isComplete);
      if (interfaceType === 'location' && !currentTask?.isDepo) {
        return router.navigate(`/${language}/dashboard/routing/${router.params.pickingSessionId || ''}/scan/item`);
      }

      return pickingSessionState({ pickingSessionId: router.params.pickingSessionId });
    }

    if (
      (prevProps.completeItemPickLoading !== completeItemPickLoading && prevProps.completeItemPickLoading && completeItemPickConfirmed) ||
      (prevProps.completeOrderLoading !== completeOrderLoading && prevProps.completeOrderLoading && completeOrderComplete)
    ) {
      return router.navigate(`/${language}/dashboard/routing/${router.params.pickingSessionId || ''}/done`);
    }

    if (prevProps.currentStateLoading !== currentStateLoading && prevProps.currentStateLoading && currentState) {
      return this.setState({ showConfirm: true });
    }

    if (prevProps.stopPickingSessionLoading !== stopPickingSessionLoading && prevProps.stopPickingSessionLoading && stopPickingSessionComplete) {
      return router.navigate(`/${language}/dashboard/routing`);
    }

    if (prevProps.validateToteCompleteLoading !== validateToteCompleteLoading && prevProps.validateToteCompleteLoading && validateToteCompleteComplete) {
      return this.setState({ validOrder: true });
    }
  }

  public stopPickingSession() {
    const { router, stopPickingSession } = this.props;

    stopPickingSession({
      pickingSessionId: router.params.pickingSessionId || '',
    });
  }

  public confirm() {
    const {
      currentRoute,
      pickingTaskId,
      pickingTaskCompleteId,
      confirmValidateItemLocation,
      confirmValidateLocationComplete,
      confirmValidateItemQuantity,
      requiredQuantity,
      router,
    } = this.props;
    const { interfaceType } = this.state;

    const currentTask = (currentRoute?.tasks || []).find((p) => !p.isComplete);

    this.setState({ confirmed: true }, () => {
      if (interfaceType === 'location' && !currentTask?.isDepo) {
        return confirmValidateItemLocation({
          pickingSessionId: router.params.pickingSessionId || '',
          pickingTaskId: pickingTaskId || '',
        });
      }

      if (interfaceType === 'location' && currentTask?.isDepo) {
        return confirmValidateLocationComplete({
          pickingSessionId: router.params.pickingSessionId || '',
          pickingTaskId: pickingTaskCompleteId || '',
        });
      }

      confirmValidateItemQuantity({
        pickingSessionId: router.params.pickingSessionId || '',
        pickingTaskId: pickingTaskId || '',
        quantity: requiredQuantity || 0,
      })
    });
  }

  public home() {
    const { language, router } = this.props;

    router.navigate(`/${language}/dashboard/routing`);
  }

  public validateOrder() {
    const { validateToteComplete, router, pickingTaskCompleteId, pickingTaskId, currentState } = this.props;

    return validateToteComplete({
      pickingSessionId: router.params.pickingSessionId || '',
      pickingTaskId: pickingTaskCompleteId || pickingTaskId || '',
      orderBarcode: (currentState?.tasks || []).find((r: CurrentPickingStateTask) => r.pickingTaskId === (pickingTaskCompleteId || pickingTaskId || ''))?.orderReference || '',
    });
  }

  public comfirmOrder() {
    const { currentRoute, completeItemPick, completeOrder, router, pickingTaskCompleteId, pickingTaskId } = this.props;

    const currentTask = (currentRoute?.tasks || []).find((p) => !p.isComplete);

    if (!currentTask?.isDepo) {
      return completeItemPick({
        pickingSessionId: router.params.pickingSessionId || '',
        pickingTaskId: pickingTaskId || '',
      });
    }

    return completeOrder({
      pickingSessionId: router.params.pickingSessionId || '',
      pickingTaskId: pickingTaskCompleteId || '',
    });
  }

  public render() {
    const { confirmed, interfaceType, showConfirm, validOrder } = this.state;
    const {
      confirmedByAPI,
      confirmedByAPI2,
      confirmedByAPI3,
      loading,
      locationBarcode,
      locationCompleteBarcode,
      itemName,
      stopPickingSessionLoading,
      requiredQuantity,
      confirmLocationLoading,
      confirmQuantityLoading,
      validateToteCompleteLoading,
      completeItemPickLoading,
    } = this.props;

    const errored = confirmed && !(confirmedByAPI || confirmedByAPI2 || confirmedByAPI3) && !loading;
    const buttonLoading = (interfaceType === 'location' && confirmLocationLoading) ||
      (interfaceType !== 'location' && confirmQuantityLoading);

    return (
      <>
        <AppBar position="static" color="transparent">
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <IconButton onClick={this.home}>
              <HomeIcon />
            </IconButton>

            <LoadingIconButton onClick={this.stopPickingSession} loading={stopPickingSessionLoading}>
              <HighlightOffIcon />
            </LoadingIconButton>
          </Toolbar>
        </AppBar>

        <Box className="tab-content-inner" style={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Box
            style={{
              position: 'absolute',
              top: 0,
              width:'100%',
              backgroundColor: confirmed ? errored ? '#ef5350' : '#80ff33' : '#F1F1F1',
              textAlign: 'center',
              color: '#333',
            }}
            p={4}
          >
            <Typography variant="h2" style={{ fontWeight: 700 }}>{interfaceType === 'location' ? 'Location' : 'Item'}</Typography>
          </Box>

          <Box
            style={{
              position: 'relative',
              backgroundColor: confirmed ? errored ? '#ef5350' : '#80ff33' : '#F1F1F1',
              textAlign: 'center',
              color: '#333',
              borderRadius: '12px',
            }}
            p={3}
          >
            <Typography variant="h4">{interfaceType !== 'location' ? itemName : locationCompleteBarcode || locationBarcode}</Typography>

            {interfaceType !== 'location' && (<StyledQtFab color={confirmed ? errored ? 'error' : 'success' : 'primary'}>
              <Typography variant="h4">{requiredQuantity}</Typography>
            </StyledQtFab>)}
          </Box>
        
          <StyledFab
            color="inherit"
            onClick={!buttonLoading ? this.confirm : undefined} style={{
              backgroundColor: 'white',
              border: confirmed ? errored ? '2px solid #ef5350' : '2px solid #80ff33' : '2px solid black'
            }}
          >
            {buttonLoading ? (
              <CircularProgress color="primary" size={24} />
            ) : (
              <CheckIcon fontSize="large" style={{ color: confirmed ? errored ? '#ef5350' : '#80ff33' : 'back' }} />
            )}
          </StyledFab>
        </Box>

        <Dialog open={showConfirm}>
          <DialogTitle>{validOrder ? 'Confirm Order' : 'Validate Order'}</DialogTitle>
          <DialogActions>
            <LoadingButton
              variant="contained"
              color="primary"
              loading={validateToteCompleteLoading || completeItemPickLoading}
              onClick={validOrder ? this.comfirmOrder : this.validateOrder}
            >
              {validOrder ? 'Confirm Order' : 'Validate Order'}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default ScanPlaceholder;
