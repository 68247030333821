import { createAction } from 'typesafe-actions';

export enum ShallowLoadingTypes {
  CHANGE_WS_STATUS = '@@shallow-loading/CHANGE_WS_STATUS',
  CHANGE_ROUTING_WS_STATUS = '@@shallow-loading/CHANGE_ROUTING_WS_STATUS',
}

export enum ReconnectingStates {
  DISCONNECTED = 'disconnected',
  RECONNECTING = 'reconnecting',
  CONNECTED = 'connected',
}

export const changeWSStatus = createAction(
  ShallowLoadingTypes.CHANGE_WS_STATUS,
  (reconnecting: ReconnectingStates) => reconnecting
)();

export const changeRoutingWSStatus = createAction(
  ShallowLoadingTypes.CHANGE_ROUTING_WS_STATUS,
  (reconnecting: ReconnectingStates) => reconnecting
)();
