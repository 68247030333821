import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import Done from './Done';
import {
  fetchCurrentRoute,
  FetchCurrentSession,
  quickOrder,
  stopPickingSession,
} from '@actions/index';

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
  currentLoading: state.routing.currentRoute.loading,
  currentRoute: state.routing.currentRoute.currentRoute,
  stopLoading: state.routing.stopPickingSession.loading,
  stopConfirmed: state.routing.stopPickingSession.complete
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCurrentRoute: (properties: FetchCurrentSession) =>
    dispatch(fetchCurrentRoute.request(properties)),
  quickOrder: () => dispatch(quickOrder.request()),
  stopPickingSession: (properties: FetchCurrentSession) =>
    dispatch(stopPickingSession.request(properties)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Done));
