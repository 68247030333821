import { ActionType, getType } from 'typesafe-actions';

import * as types from '@actions/shallow-loading';
import * as employees from '@actions/employees';
import * as floorplans from '@actions/floorplans';
import * as items from '@actions/items';
import * as orders from '@actions/orders';
import * as organisation from '@actions/organisation';
import * as sensors from '@actions/sensors';
import * as rules from '@actions/rules';
import * as simulations from '@actions/simulations';
import { ReconnectingStates } from '@actions/shallow-loading';

type SensorsAction = ActionType<
  // eslint-disable-next-line prettier/prettier
  typeof employees | typeof orders | typeof items | typeof floorplans | typeof organisation | typeof sensors | typeof types | typeof rules | typeof simulations
>;

export interface IShallowLoadingState {
  readonly loading: Record<string, boolean>;
  readonly reconnecting?: ReconnectingStates;
  readonly routingReconnecting?: ReconnectingStates;
}

export const initialState: IShallowLoadingState = {
  loading: {},
};

export default (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: IShallowLoadingState = initialState,
  action: SensorsAction
): IShallowLoadingState => {
  switch (action.type) {
    case getType(organisation.fetchOrganisationInformation.request): {
      const { shallow } = action.payload;
      const { fetchOrganisationInformation } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchOrganisationInformation:
            shallow === true ? true : fetchOrganisationInformation,
        },
      };
    }

    case getType(organisation.fetchOrganisationInformation.success):
    case getType(organisation.fetchOrganisationInformation.failure): {
      const { shallow } = action.payload;
      const { fetchOrganisationInformation } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchOrganisationInformation:
            shallow === true ? false : fetchOrganisationInformation,
        },
      };
    }

    case getType(sensors.fetchFloorplanSensors.request): {
      const { shallow } = action.payload;
      const { fetchFloorplanSensors } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchFloorplanSensors:
            shallow === true ? true : fetchFloorplanSensors,
        },
      };
    }

    case getType(sensors.fetchFloorplanSensors.success):
    case getType(sensors.fetchFloorplanSensors.failure): {
      const { shallow } = action.payload;
      const { fetchFloorplanSensors } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchFloorplanSensors:
            shallow === true ? false : fetchFloorplanSensors,
        },
      };
    }

    case getType(sensors.fetchSensorGroupsByFloorplan.request): {
      const { shallow } = action.payload;
      const { fetchSensorGroupsByFloorplan } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchSensorGroupsByFloorplan:
            shallow === true ? true : fetchSensorGroupsByFloorplan,
        },
      };
    }

    case getType(sensors.fetchSensorGroupsByFloorplan.success):
    case getType(sensors.fetchSensorGroupsByFloorplan.failure): {
      const { shallow } = action.payload;
      const { fetchSensorGroupsByFloorplan } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchSensorGroupsByFloorplan:
            shallow === true ? false : fetchSensorGroupsByFloorplan,
        },
      };
    }

    case getType(sensors.fetchFloorplanSensorsStatus.request): {
      const { shallow } = action.payload;
      const { fetchFloorplanSensorsStatus } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchFloorplanSensorsStatus:
            shallow === true ? true : fetchFloorplanSensorsStatus,
        },
      };
    }

    case getType(sensors.fetchFloorplanSensorsStatus.success):
    case getType(sensors.fetchFloorplanSensorsStatus.failure): {
      const { shallow } = action.payload;
      const { fetchFloorplanSensorsStatus } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchFloorplanSensorsStatus:
            shallow === true ? false : fetchFloorplanSensorsStatus,
        },
      };
    }

    case getType(sensors.fetchFloorplanSensorGroupStatus.request): {
      const { shallow } = action.payload;
      const { fetchFloorplanSensorGroupStatus } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchFloorplanSensorGroupStatus:
            shallow === true ? true : fetchFloorplanSensorGroupStatus,
        },
      };
    }

    case getType(sensors.fetchFloorplanSensorGroupStatus.success):
    case getType(sensors.fetchFloorplanSensorGroupStatus.failure): {
      const { shallow } = action.payload;
      const { fetchFloorplanSensorGroupStatus } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchFloorplanSensorGroupStatus:
            shallow === true ? false : fetchFloorplanSensorGroupStatus,
        },
      };
    }

    case getType(floorplans.updateFloorplan.request): {
      const { shallow } = action.payload;
      const { updateFloorplan } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          updateFloorplan: shallow === true ? true : updateFloorplan,
        },
      };
    }

    case getType(floorplans.updateFloorplan.success):
    case getType(floorplans.updateFloorplan.failure): {
      const { shallow } = action.payload;
      const { updateFloorplan } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          updateFloorplan: shallow === true ? false : updateFloorplan,
        },
      };
    }

    case getType(employees.fetchEmployees.request): {
      const { shallow } = action.payload;
      const { fetchEmployees } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchEmployees: shallow === true ? true : fetchEmployees,
        },
      };
    }

    case getType(employees.fetchEmployees.success):
    case getType(employees.fetchEmployees.failure): {
      const { shallow } = action.payload;
      const { fetchEmployees } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchEmployees: shallow === true ? false : fetchEmployees,
        },
      };
    }

    case getType(items.fetchItemsWithLocations.request): {
      const { shallow } = action.payload;
      const { fetchItemsWithLocations } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchItemsWithLocations:
            shallow === true ? true : fetchItemsWithLocations,
        },
      };
    }

    case getType(items.fetchItemsWithLocations.success):
    case getType(items.fetchItemsWithLocations.failure): {
      const { shallow } = action.payload;
      const { fetchItemsWithLocations } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchItemsWithLocations:
            shallow === true ? false : fetchItemsWithLocations,
        },
      };
    }

    case getType(orders.fetchOrdersWithItems.request): {
      const { shallow } = action.payload;
      const { fetchOrdersWithItems } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchOrdersWithItems: shallow === true ? true : fetchOrdersWithItems,
        },
      };
    }

    case getType(orders.fetchOrdersWithItems.success):
    case getType(orders.fetchOrdersWithItems.failure): {
      const { shallow } = action.payload;
      const { fetchOrdersWithItems } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchOrdersWithItems: shallow === true ? false : fetchOrdersWithItems,
        },
      };
    }

    case getType(rules.fetchTemplates.request): {
      const { shallow } = action.payload;
      const { fetchTemplates } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchTemplates: shallow === true ? true : fetchTemplates,
        },
      };
    }

    case getType(rules.fetchTemplates.success):
    case getType(rules.fetchTemplates.failure): {
      const { shallow } = action.payload;
      const { fetchTemplates } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchTemplates: shallow === true ? false : fetchTemplates,
        },
      };
    }

    case getType(rules.fetchRules.request): {
      const { shallow } = action.payload;
      const { fetchRules } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchRules: shallow === true ? true : fetchRules,
        },
      };
    }

    case getType(rules.fetchRules.success):
    case getType(rules.fetchRules.failure): {
      const { shallow } = action.payload;
      const { fetchRules } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchRules: shallow === true ? false : fetchRules,
        },
      };
    }

    case getType(simulations.fetchSimulations.request): {
      const { shallow } = action.payload;
      const { fetchSimulations } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchSimulations: shallow === true ? true : fetchSimulations,
        },
      };
    }

    case getType(simulations.fetchSimulations.success):
    case getType(simulations.fetchSimulations.failure): {
      const { shallow } = action.payload;
      const { fetchSimulations } = state.loading;

      return {
        ...state,
        loading: {
          ...state.loading,
          fetchSimulations: shallow === true ? false : fetchSimulations,
        },
      };
    }

    case getType(types.changeWSStatus):
      return {
        ...state,
        reconnecting: action.payload,
      };

    case getType(types.changeRoutingWSStatus):
      return {
        ...state,
        routingReconnecting: action.payload,
      };

    default:
      return state;
  }
};
