import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  confirmValidateItemLocation,
  confirmValidateLocationComplete,
  confirmValidateItemQuantity,
  IConfirmValidateItemLocation,
  IConfirmValidateItemQuantity,
  StopPickingSession,
  stopPickingSession,
  ValidateToteComplete,
  validateToteComplete,
  CompleteItemPick,
  completeItemPick,
  FetchCurrentSession,
  pickingSessionState,
  CompleteOrder,
  completeOrder,
} from '@actions/routing';
import { withRouter } from '@app/utils/withRouter';
import { IAppState } from '@reducers/index';
import { getActiveLanguage } from '@selectors/app';
import ScanResult from './ScanResult';

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
  confirmLocationLoading: state.routing.confirmValidateItemLocation.loading,
  confirmLocationCompleteLoading: state.routing.confirmValidateLocationComplete.loading,
  confirmQuantityLoading: state.routing.confirmValidateItemQuantity.loading,
  loading: state.routing.confirmValidateItemLocation.loading ||
    state.routing.confirmValidateLocationComplete.loading ||
    state.routing.confirmValidateItemQuantity.loading,
  confirmedByAPI: state.routing.confirmValidateItemLocation.confirmed,
  confirmedByAPI2: state.routing.confirmValidateLocationComplete.confirmed,
  confirmedByAPI3: state.routing.confirmValidateItemQuantity.confirmed,
  locationBarcode: state.routing.validateItemLocation.barcode,
  locationCompleteBarcode: state.routing.validateLocationComplete.barcode,
  pickingTaskId: state.routing.validateItemLocation.pickingTaskId,
  pickingTaskCompleteId: state.routing.validateLocationComplete.pickingTaskId,
  itemName: state.routing.validateItemQuantity.itemName,
  requiredQuantity: state.routing.validateItemQuantity.requiredQuantity,
  stopPickingSessionLoading: state.routing.stopPickingSession.loading,
  stopPickingSessionComplete: state.routing.stopPickingSession.complete,
  validateToteCompleteLoading: state.routing.validateToteComplete.loading,
  validateToteCompleteComplete: state.routing.validateToteComplete.complete,
  completeItemPickLoading: state.routing.completeItemPick.loading,
  completeItemPickConfirmed: state.routing.completeItemPick.confirmed,
  currentRoute: state.routing.currentRoute.currentRoute,
  currentState: state.routing.currentState.currentState,
  currentStateLoading: state.routing.currentState.loading,
  completeOrderLoading: state.routing.completeOrder.loading,
  completeOrderComplete: state.routing.completeOrder.complete,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  confirmValidateItemLocation: (properties: IConfirmValidateItemLocation) =>
    dispatch(confirmValidateItemLocation.request(properties)),
  confirmValidateLocationComplete: (properties: IConfirmValidateItemLocation) =>
    dispatch(confirmValidateLocationComplete.request(properties)),
  confirmValidateItemQuantity: (properties: IConfirmValidateItemQuantity) =>
    dispatch(confirmValidateItemQuantity.request(properties)),
  stopPickingSession: (properties: StopPickingSession) =>
    dispatch(stopPickingSession.request(properties)),
  validateToteComplete: (properties: ValidateToteComplete) =>
    dispatch(validateToteComplete.request(properties)),
  completeItemPick: (properties: CompleteItemPick) =>
    dispatch(completeItemPick.request(properties)),
  pickingSessionState: (properties: FetchCurrentSession) =>
    dispatch(pickingSessionState.request(properties)),
  completeOrder: (properties: CompleteOrder) =>
    dispatch(completeOrder.request(properties)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScanResult));
