import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { FetchCurrentSession } from '@actions/routing';
import { CurrentPickingRoute } from '@models/Order';
import Loading from '@app/common/Loading';

interface IProps {
  router: any;
  language: string;
  quickOrder: () => void;
  fetchCurrentRoute: (props: FetchCurrentSession) => void;
  currentLoading: boolean;
  currentRoute?: CurrentPickingRoute;
  stopLoading: boolean;
  stopConfirmed: boolean;
  stopPickingSession: (props: FetchCurrentSession) => void;
}
interface IState {
  order: boolean;
}

class Done extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      order: false,
    };

    this.confirm = this.confirm.bind(this);
  }

  public componentDidMount() {
    const { fetchCurrentRoute, router } = this.props;

    fetchCurrentRoute({ pickingSessionId: router.params.pickingSessionId });
  }

  public componentDidUpdate(prevProps: IProps) {
    const {
      currentLoading,
      currentRoute,
      stopLoading,
      stopConfirmed,
      quickOrder,
      language,
      router,
    } = this.props;
    const { order } = this.state;

    if (prevProps.currentLoading !== currentLoading && prevProps.currentLoading && currentRoute) {
      const hasIncomplete = currentRoute?.tasks.find((t) => !t.isComplete);
      if (hasIncomplete) {
        return router.navigate(`/${language}/dashboard/routing/${router.params.pickingSessionId}/scan/location`);
      }
    }

    if (prevProps.stopLoading !== stopLoading && prevProps.stopLoading && stopConfirmed) {
      if (order) {
        quickOrder();
      }
  
      return router.navigate(`/${language}/dashboard/routing`);
    }
  }

  public confirm(order: boolean) {
    const { stopPickingSession, router } = this.props;

    this.setState({ order }, () => {
      stopPickingSession({
        pickingSessionId: router.params.pickingSessionId,
      });
    });
  }

  public render() {
    const { currentLoading } = this.props;

    return (
      <Box className="tab-content-inner">
        <Box p={2}>
          <Loading loading={currentLoading}>
            <Button variant="contained" onClick={() => this.confirm(true)} fullWidth>
              <Typography variant="h3" style={{ fontWeight: 700 }}>Confirm & Quick Order</Typography>
            </Button>

            <Box pt={2}>
              <Button variant="contained" onClick={() => this.confirm(false)} fullWidth>
                <Typography variant="h3" style={{ fontWeight: 700 }}>Confirm & End Session</Typography>
              </Button>
            </Box>
          </Loading>
        </Box>
      </Box>
    );
  }
}

export default Done;
