import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  autoLocate,
  validateItemLocation,
  validateLocationComplete,
  validateItemQuantity,
  IAutoLocate,
  IValidateItemLocation,
  IValidateItemQuantity,
  FetchCurrentSession,
  fetchCurrentRoute,
  StopPickingSession,
  stopPickingSession,
} from '@actions/index';
import { IAppState } from '@reducers/index';
import { withRouter } from '@app/utils/withRouter';
import { getActiveLanguage } from '@selectors/app';
import ScanPlaceholder from './ScanPlaceholder';

const mapStateToProps = (state: IAppState) => ({
  language: getActiveLanguage(state),
  loading: state.routing.autoLocate.loading ||
    state.routing.validateItemLocation.loading ||
    state.routing.validateItemQuantity.loading,
  validateLocationLoading: state.routing.validateItemLocation.loading,
  validateLocationConfirmLoading: state.routing.validateLocationComplete.loading,
  validateQuantityLoading: state.routing.validateItemQuantity.loading,
  autoLocateLoading: state.routing.autoLocate.loading,
  currentLoading: state.routing.currentRoute.loading,
  currentRoute: state.routing.currentRoute.currentRoute,
  locationId: state.routing.validateItemLocation.locationId,
  stopPickingSessionLoading: state.routing.stopPickingSession.loading,
  stopPickingSessionComplete: state.routing.stopPickingSession.complete,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  autoLocate: (properties: IAutoLocate) =>
    dispatch(autoLocate.request(properties)),
  validateItemLocation: (properties: IValidateItemLocation) =>
    dispatch(validateItemLocation.request(properties)),
  validateLocationComplete: (properties: IValidateItemLocation) =>
    dispatch(validateLocationComplete.request(properties)),
  validateItemQuantity: (properties: IValidateItemQuantity) =>
    dispatch(validateItemQuantity.request(properties)),
  fetchCurrentRoute: (properties: FetchCurrentSession) =>
    dispatch(fetchCurrentRoute.request(properties)),
  stopPickingSession: (properties: StopPickingSession) =>
    dispatch(stopPickingSession.request(properties)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScanPlaceholder));
